.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #fff1;
}

.loginContainer .loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  background-color: #fff1;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.loginContainer .loginForm h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
  margin-bottom: 1rem;
}

.loginContainer .loginForm p.error {
  color: #ff0000;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.loginContainer .loginForm input {
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.loginContainer .loginForm input:focus {
  outline: none;
}

.loginContainer .loginForm input.error {
  border: 3px solid #ff0000;
}

.loginContainer .loginForm button {
  width: 100%;
  padding: 0.6rem 1rem;
  border: none;
  border-radius: 8px;
  background-color: #7284ff;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.loginContainer .loginForm button:hover {
  cursor: pointer;
  background-color: #5c6fff;
}

.loginContainer .loginForm button:active {
  background-color: #4b5fff;
}