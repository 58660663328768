.imageClassifierContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.imageClassifierContainer .informationModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    transition: 0.25s;
}

.imageClassifierContainer .informationModalContainer.hidden {
    display: none;
}

.imageClassifierContainer .informationModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    padding: 1rem;
    max-width: 50rem;
    margin: 1rem;
    background-color: rgba(121, 121, 121, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.imageClassifierContainer .informationModal p,
.imageClassifierContainer .informationModal h1,
.imageClassifierContainer .informationModal h2,
.imageClassifierContainer .informationModal h3,
.imageClassifierContainer .informationModal h4 {
    margin: 0;
    padding: 0;
}

.imageClassifierContainer .informationModal p {
    font-size: 1.2rem;
    color: #e7e7e7
}

.imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;
    background-color: #fff1;
    gap: 1rem;
}

.imageContainer .logOutContainer {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.imageContainer .logOutContainer div {
    padding: 0.4rem 1rem;
    border: none;
    border-radius: 8px;
    background-color: #fff2;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.imageContainer .logOutContainer button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 8px;
    background-color: #7284ff;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.imageContainer .logOutContainer button:hover {
    cursor: pointer;
    background-color: #5c6fff;
}

.imageContainer .logOutContainer button:active {
    background-color: #4b5fff;
}

.selectionContainer {
    position: relative;
}

.selection {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px #ffffff;
}

.classificationArea {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px dashed #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.classificationArea:hover {
    cursor: pointer;
    border: 2px solid #ffffff;
    box-shadow: 0 0 20px;
    opacity: 1 !important;
}

.classificationArea.backgrounded {
    opacity: 0.7;
}

.classificationArea.selected {
    animation: classificationSelectedAnimation 1.5s infinite;
    border: 3px solid #4e88df;
    box-shadow: 0 0 25px 0 #4e88df;
    z-index: 1;
}

.classificationArea.selected:hover {
    cursor: pointer;
    border: 3px solid #4e88df;
    box-shadow: 0 0 20px 0 #fff !important;
}

@keyframes classificationSelectedAnimation {
    0% {
        box-shadow: 0px 0px 25px 0px #4e88df;
    }
    50% {
        box-shadow: 0px 0px 25px 0px #4e88df50
    }
    100% {
        box-shadow: 0px 0px 25px 0px #4e88df;
    }
}

.classificationArea .removeAreaButton {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 1.8rem;
    font-weight: 600;
}

.classificationArea span {
    font-size: 2rem;
    font-weight: 600;
    color: #ffffff;
}

.mainImage {
    max-width: 80vw;
    max-height: 60vh;
    border-radius: 15px;
    box-shadow: 0px 0px 12px 0px #2a2a2a;;
    opacity: 1;
    transition: 0.25s;
    image-orientation: none;
}

.mainImage.selected {
    /* animation: classificationSelectedAnimation 1.5s infinite; */
    box-shadow: 0 0 20px 0 #4e88df;
}

.mainImage.loading {
    opacity: 0;
}

.instructionsContainer {
    font-size: 1.2rem;
    text-align: center;
    max-width: 80vw;
}

.instructionsContainer.hidden {
    transition: 0.25s;
    opacity: 0;
}

.classifierContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    min-height: 25vh;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 1px #1c1c1c;
    padding: 1rem;
}

.classifierAreaTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.classifierAreaTab {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background: #fff6;
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.classifierAreaTab:hover {
    cursor: pointer;
    border-bottom: 4px solid #f5f5f5;
}

.classifierAreaTab:active {
    border-bottom: 2px solid #e5e5e5;
}

.classifierAreaTab.selected {
    border-bottom: 4px solid #7284ff;
}

.classifierAreaTab.required {
    background: #f776 !important;
}

.classifierSections {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.classifierSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
    background-color: #fff1;
    border-radius: 8px;
    gap: 0.8rem;
}

@media (max-width: 900px) {
    .classifierSection {
        width: 100%;
    }
}

.classifierSection span {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
}

.classifierSection span {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: #fff;
}

.classifierSection.required {
    background: #f002;
    border: 2px solid #f006;
}

.classifierButtonContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.classifierButtonContainer .classifierButton:first-child {
    border-radius: 10px 0 0 10px;
}

.classifierButtonContainer .classifierButton:last-child {
    border-radius: 0 10px 10px 0;
}

@media (max-width: 900px) {
    .classifierButtonContainer {
        gap: 0.5rem;
    }

    .classifierButtonContainer .classifierButton {
        border-radius: 10px !important;
    }
}

.classifierControls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .6rem;
}

.classifierButton {
    padding: 1rem 1rem;
    font-size: 1rem;
    color: #fff;
    background: #fff6;
    border: none;
    transition: 0.15s;
}

.classifierButton:hover {
    background: #fff8;
    cursor: pointer;
}

.classifierButton.selected {
    background: #7284ffaa;
}

.classifierButton.success {
    background: #258f17;
    border-radius: 3px;
}

.classifierButton.success:hover {
    box-shadow: 0 0 6px 0 #258f17;
}

.classifierButton.success:active {
    background: #258f17aa;
}

.classifierButton.warning {
    background: #e69718;
    border-radius: 3px;
}

.classifierButton.warning:hover {
    box-shadow: 0 0 6px 0 #e69718;
}

.classifierButton.warning:active {
    background: #e69718aa;
}

.classifierButton.danger {
    background: #cf1f1f;
    border-radius: 3px;
}

.classifierButton.danger:hover {
    box-shadow: 0 0 6px 0 #cf1f1f;
}

.classifierButton.danger:active {
    background: #cf1f1faa;
}

.classifierButton.info {
    background: #1f8fcf;
    border-radius: 3px;
}

.classifierButton.info:hover {
    box-shadow: 0 0 6px 0 #1f8fcf;
}

.classifierButton.info:active {
    background: #1f8fcfaa;
}